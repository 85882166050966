require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('homePage', null);

  },  
  /* handler:onClick *//* content */
}));

define('./pages/404/components/76f80b78cc38e26389e80dd84af585d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/flooringPros/components/6cf86b384b9825f8e2f817782f887dee/bundle.js', [], () => ({
  /* content */
  /* handler:submitMultiForm */
  async ['submitMultiForm'](___arguments) {
      (function (event) { event.stopPropagation() })((await Backendless.Request.post(`${Backendless.appPath}/services/GcpService/Lead`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(___arguments.context.pageData))));

  },
  /* handler:submitMultiForm */
  /* content */
}))

define('./pages/flooringPros/components/2d882826a85f03aeaf26220483fdb542/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    
  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/flooringPros/components/04b8fb552dd9b4f29568611d2eb8af58/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:855-567-0753', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/flooringPros/components/5fbbe93817e839485358dd5e34a8f098/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:855-567-0753', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    var urlMap;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  urlMap = ({ [`solar`]: 'https://solar.prosofusa.com/',[`windows`]: 'https://windowinstaller.prosofusa.com/',[`flooring`]: 'https://flooring.prosofusa.com/',[`shutters`]: 'https://shutters.prosofusa.com/' });
  if (((function () { return window.location.href })()) == (getObjectProperty(urlMap, 'solar'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('solarInstallers', undefined);
  }
  if (((function () { return window.location.href })()) == (getObjectProperty(urlMap, 'windows'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('windowInstallers', undefined);
  }
  if (((function () { return window.location.href })()) == (getObjectProperty(urlMap, 'flooring'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('flooringPros', undefined);
  }
  if (((function () { return window.location.href })()) == (getObjectProperty(urlMap, 'shutters'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('shutters', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/shutters/components/81e8c2e8c02b73182a78e0a8e6ea1cd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('shutters', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c9c1bcb35f5b7d4b03238fcc8cff6abd/logic/359da7713c6818cf9651ce975b98e013/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('windowInstallers', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0d5feaf170c21c53af139614b1dc1459/logic/c83887e4d538ba2805583098052546a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('windowInstallers', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1b2e17dbfc6abfabc4a4a64b1f8c0494/logic/03a2f5f83a687b7815cd37583a78a0cd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('windows_privacy_policy', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_1b2e17dbfc6abfabc4a4a64b1f8c0494/logic/26689238005cb128da38d31806617a61/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('windows_terms', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ecd48e6c36f492fefe3fc529c1ca47b9/logic/71e8185c2f18a5b842996bb9df18c568/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('solarInstallers', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_530d089bfeb871076a3d7ce7df05f1db/logic/9fc703f81228dbe7f5cec90369c80318/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:888-711-0665', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a787e95c5dddea1ef0d772a20b7342cf/logic/373809c8467a5448a0c8ebbb295825e4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('flooringPros', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7c98df3b1446b6845ecb434f72539e9f/logic/7942900f0f68f4c82c16e42bf4d87fc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('flooring_terms', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7c98df3b1446b6845ecb434f72539e9f/logic/45c8c4366b98399192780525479a3f07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('flooring_privacy_policy', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7e53f6774e231a586e8e9cae5bdf2522/logic/f0728f38458efadef83cf0d403a84cc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:855-567-0753', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2cb435dbe324ade6bcdf9bef71fae1a3/logic/53b8470dfd78a8629fc87d08f7980df8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('flooringPros', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9f030237501774d85a8503369ff4c68e/logic/359da7713c6818cf9651ce975b98e013/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:855-567-0753', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_baecc23c599d19ce67b2dca2d8f6b865/logic/0be87bf247e75e3f5105c358f7d8e3bc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Solar_privacy_policy', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_baecc23c599d19ce67b2dca2d8f6b865/logic/03d1136f7bf940a81f3811887d682e37/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Solar_terms', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_baecc23c599d19ce67b2dca2d8f6b865/logic/ad93f23a54d886989ff89388e258decc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('solar_partners', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7c5ea7ea59b5321a293a9b31669205de/logic/53b8470dfd78a8629fc87d08f7980df8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('solarInstallers', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5b72f4cb976e229f5c2b4fa35bf7ce8d/logic/ed088cb8d5f831c8ccc846b884b852cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_fb57fc59e59836ec8fb80f231732e3b5/logic/0be87bf247e75e3f5105c358f7d8e3bc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Shutters_privacy_policy', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_fb57fc59e59836ec8fb80f231732e3b5/logic/03d1136f7bf940a81f3811887d682e37/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Shutters_terms', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_fb57fc59e59836ec8fb80f231732e3b5/logic/ad93f23a54d886989ff89388e258decc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Shutters_partners', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0dfbdead4265e52cc66c9ae38f98b9fa/logic/27a8edd85808d1e8eac8ac54ec0840ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('shutters', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_209133e7b1148fc892647d14744e6b2c/logic/53b8470dfd78a8629fc87d08f7980df8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('shutters', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_9f49568a52f938a8fda397663f0c3574/logic/e8e8755abb324e19c618243d6ff6ce15/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:888-711-0665', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

